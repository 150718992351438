<template>
  <div class="main-search-box">
    <el-form ref="form" inline :model="searchForm" class="demo-form-inline">
      <el-form-item label="单位类别"  label-width="80px">
        <el-select clearable class="w300" v-model="searchForm.type" @change="handlerChangeType" placeholder="请选择单位类别">
          <el-option v-for="(item, index) in unitTypeConfig" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <!--显示企业-->
      <el-form-item v-if="searchForm.type === 1" label="单位性质" label-width="80px" >
        <el-select clearable class="w300" v-model="searchForm.characteristic" placeholder="请选择单位性质">
          <el-option v-for="(item, index) in unitNatureCompany" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <!--显示政府-->
      <el-form-item v-else label="单位性质" label-width="80px" >
        <el-select clearable class="w300" v-model="searchForm.characteristic" placeholder="请选择单位性质">
          <el-option v-for="(item, index) in unitNatureGover" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="单位名称" label-width="80px" >
        <el-input clearable class="w300" placeholder="请输入单位名称" v-model="searchForm.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { UNITCATEGORY, UNITNATURE_ENTERPRISE, UNITNATURE_GOVERNMENT } from "../dataDictionary"
  export default {
    name: "searchForm",
    data() {
      return {
        unitTypeConfig: UNITCATEGORY, //单位类别
        unitNatureCompany: [], //单位性质（企业）
        unitNatureGover: [], //单位性质（政府）
        searchForm: {
          type: '', //单位类别
          characteristic: '', //单位性质
          name: '', //单位名称
        }
      }
    },
    methods: {
      /*回传查询参数*/
      onSubmit() {
        this.$emit('searchFromData', this.searchForm)
      },
      /*切换单位类型*/
      handlerChangeType(data) {
        // this.searchForm.type = data
        this.searchForm.characteristic = ''
        if(data === 1) {
          this.unitNatureCompany = UNITNATURE_ENTERPRISE
        }else if (data === 2) {
          this.unitNatureGover = UNITNATURE_GOVERNMENT
        }else {
          //清空单位性质
          this.searchForm.characteristic = ''
          this.unitNatureGover = []
        }

      }
    }
  }
</script>

<style scoped>

</style>

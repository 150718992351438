// 数据字典 dataDictionary

/**
 * 响应机制--适用事件
 */
export const APPLYEVENTS = [
  {
    label: '防盗风险',
    value: 0
  },
  {
    label: '火灾风险',
    value: 1
  },
  {
    label: '室外突发',
    value: 2
  },
  {
    label: '活跃异常',
    value: 3
  },
  {
    label: '走丢风险',
    value: 4
  },
  {
    label: '紧急风险',
    value: 5
  },
  {
    label: '燃气泄漏',
    value: 6
  },
  {
    label: '夜间异常',
    value: 7
  },
  {
    label: '水浸风险',
    value: 8
  }
]

/**
 * 响应机制--使用状态
 */
export const PROUSETYPE = [
  {
    label: '启用中',
    value: 0
  },
  {
    label: '禁用中',
    value: 1
  },
  {
    label: '编辑中',
    value: 2
  }
]

/**
 * 响应机制--使用状态
 */
export const TIMETYPE = [
  {
    label: '秒',
    value: 0
  },
  {
    label: '分',
    value: 1
  }
]

/**
 * 基础数据管理--审核状态
 */
export const AUDITOPTIONS = [
  {
    label: '待审核',
    value: 1
  },
  {
    label: '审核通过',
    value: 2
  },
  {
    label: '审核不通过',
    value: 3
  }
]

/**
 * 基础数据管理--部门类型
 */
export const ORGANTYPE = [
  {
    label: '内部管理部门',
    value: 1
  },
  {
    label: '独立运营部门(养老中心、站点、养老机构)',
    value: 2
  }
]

/**
 * 基础数据管理--单位类别
 */
export const UNITCATEGORY = [
  {
    label: '企业',
    value: 1
  },
  {
    label: '政府',
    value: 2
  }
]

/**
 * 基础数据管理--单位类别(政府)
 */
export const UNITNATURE_GOVERNMENT = [
  // {
  //   label: '中国政府',
  //   value: 1
  // },
  // {
  //   label: '省政府',
  //   value: 2
  // },
  // {
  //   label: '市政府',
  //   value: 3
  // },
  {
    label: '区县政府',
    value: 4
  },
  {
    label: '乡镇(街道)',
    value: 5
  },
  {
    label: '社区(乡村)',
    value: 6
  }
]

/**
 * 基础数据管理--单位类别(企业)
 */
export const UNITNATURE_ENTERPRISE = [
  {
    label: '运营机构',
    value: 7
  },
  {
    label: '养老机构',
    value: 8
  },
  {
    label: '养老服务中心',
    value: 9
  },
  {
    label: '养老服务站',
    value: 10
  },
  {
    label: '医疗机构',
    value: 11
  },
  {
    label: '其他',
    value: 12
  },
  {
    label: '评估机构',
    value: 13
  }
]

/**
 * 基础数据管理--单位所属系统
 */
export const UNITSYSTEM = [
  {
    label: '民政监管系统(区县)',
    value: 1
  },
  {
    label: '运营监管系统(镇街)',
    value: 2
  },
  {
    label: '养老机构系统',
    value: 3
  },
  {
    label: '医疗卫生系统',
    value: 4
  },
  {
    label: '养老评估系统',
    value: 5
  }
]

/**
 * 基础数据管理--单位级别
 */
export const UNITLEVEL = [
  // {
  //   label: '省部级',
  //   value: 1
  // },
  // {
  //   label: '市级',
  //   value: 2
  // },
  {
    label: '区县级',
    value: 3
  },
  {
    label: '乡镇(街道)级',
    value: 4
  },
  {
    label: '村(社区)级',
    value: 5
  }
]

export const SIGNOPTIONS = [
  {
    label: '中国政府',
    value: 1
  },
  {
    label: '省政府',
    value: 2
  },
  {
    label: '市政府',
    value: 3
  },
  {
    label: '区县政府',
    value: 4
  },
  {
    label: '乡镇(街道)',
    value: 5
  },
  {
    label: '社区(乡村)',
    value: 6
  },
  {
    label: '运营机构',
    value: 7
  },
  {
    label: '养老机构',
    value: 8
  },
  {
    label: '养老服务中心',
    value: 9
  },
  {
    label: '养老服务站',
    value: 10
  },
  {
    label: '医疗机构',
    value: 11
  },
  {
    label: '其他(不计入养老统计)',
    value: 12
  },
  {
    label: '评估机构',
    value: 13
  }
]
